<template>
  <div class="newscenter">
    <div class="bg">
      <img
        src="@imgs/xwzxbg.png"
        width="194px"
        height="93px"
        alt=""
        class="cpzx"
      />
    </div>
    <div class="newscontent">
      <div class="contentbox">
        <div class="left" v-show="id == 0">
          <div class="left-title">
            <div class="title">四川得荣：桃花恣意绽放 美成人间仙境</div>
            <div class="timer">2021-04-12 15：25</div>
          </div>
          <div class="details">
             <div>
                  3月29日下午，云红药业党支部联合广发行昆明海源支行党支部在公司3楼开展“银企共学共助”主题党日活动。活动首先参观了公司党建文化长廊，接着在党建大厅前重温入党誓词；
                  之后在党建会议室进行党史学习，观看《陪毛泽东转战陕北的小青马》，领会伟大领袖在长征中的奋斗历史。最后公司熊总与广发行杨红同志进行银企共助沟通和探讨。
              </div>
                
            <img src="@imgs/news-d.jpg" alt="" />
            <div>
                  本次活动，通过两个党支部的互相学习，面对面的沟通，提高了党员的思想觉悟。
                  活动中，双方深入沟通了上下游合作情况，探讨了银企互利共赢的合作发展方向，为“十四五”期间银行大力支持民营企业经济发展和业务拓展奠定了基础。
            </div>
          </div>
        </div>

        <div class="left" v-show="id == 1">
          <div class="left-title">
            <div class="title">云红药业捐赠昆明市慈善总会4万余元</div>
            <div class="timer">2021-04-12 15：25</div>
          </div>
          <div class="details">
             <div>
                  为支持云南省昆明市地区医疗健康保险的发展，共同为构建和谐社会做出贡献，云南云红药业有限公司于3月24日对昆明市慈善总会进行无偿捐赠，
                  捐赠资产为“春城惠民保保险”，首批捐赠保单600份，价值人民币41400元。
              </div>
                
            <img src="@imgs/news-d.jpg" alt="" />
            <div>
              云南云红药业有限公司在发展过程中，积极承担社会责任，倡导企业、员工、社会共同发展的理念。
                  近年来，在新冠疫情防控、扶贫攻坚、爱心助学、促进就业等方面发挥着自己的作用，贡献出企业的一份力量。
            </div>
          </div>
        </div>
        <div class="right">
          <div class="search">
            <input type="text" placeholder="搜索" />
            <img src="@imgs/search.png" width="17px" height="17px" alt="" />
          </div>
          <div class="recommend">
            <div class="wntj">为你推荐</div>
            <div class="gd">
              更多<img src="@imgs/gt.png" width="6px" height="11px" alt="" />
            </div>
          </div>
          <div class="recommendlist">
            <div class="item" v-for="item in newsList" :key="item.id">
              <div class="square"></div>
              <div class="recomedtitle">
                疫情挡不住温情 云南云红用实际行动传递温暖
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
      id: 0
    };
  },
  created(){
    this.id = this.$route.query.id;
  }
};
</script>

<style lang="scss" scoped>
.newscenter {

  .newscontent {
    width: 100%;
    height: 1200px;
    background: #f1f1f1;
    padding-top: 30px;
    .contentbox {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      // height: 1073px;
      justify-content: space-between;
      .left {
        width: 817.9px;
        box-sizing: border-box;
        padding: 35px;
        background: #ffffff;
        border-radius: 8px;
        text-align: left;
        .left-title {
          padding-bottom: 10px;
          border-bottom: 1px solid #e6e6e6;
          .title {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333232;
            line-height: 30px;
          }
          .timer {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            color: #666666;
            line-height: 30px;
          }
        }
        .details {
          width: 100%;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666565;
          line-height: 30px;
          img {
            width: 100%;
          }
        }
      }
      .right {
        width: 353px;
        border-radius: 8px;
        text-align: left;
        padding: 33px;
        height: 478px;
        box-sizing: border-box;
        background: #ffffff;
        .search {
          border: 1px solid #cccccc;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 11px 15px 8px 15px;
          input {
            flex: 1;
            font-size: 17px;
            outline: none;
            border: none;
          }
        }
        .recommend {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 400;
          margin-top: 28px;
          border-bottom: 1px solid #e6e6e6;
          padding-bottom: 9px;
          .wntj {
            font-size: 20px;
            font-family: Microsoft YaHei;
            color: #333333;
          }
          .gd {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: DengXian;
            color: #fe2a1e;
            img {
              margin-left: 5px;
            }
          }
        }
        .recommendlist {
          .item {
            padding-bottom: 15px;
            border-bottom: 1px dotted #cccccc;
            margin-top: 17px;
            display: flex;
            // &:nth-last-child(1){
            //   .square{
            //     background: #FE2A1E;
            //   }
            //   .recomedtitle{
            //     color: #FE2A1E;
            //   }
            // }
            &:hover {
              .square {
                background: #fe2a1e;
              }
              .recomedtitle {
                color: #fe2a1e;
              }
              cursor: pointer;
            }
            .square {
              width: 8px;
              height: 6px;
              background: #999999;
              margin-top: 7px;
              margin-right: 5px;
            }
            .recomedtitle {
              color: #666565;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666565;
            }
          }
        }
      }
    }
  }
}
</style>